<template>
  <div class="full-width">
    <div class="actions-content">
      <button
        @click="login()"
        type="button"
        class="button_outline"
        style="width:100%; color: white; background:#14a949"
        :disabled="loading"
      >
        {{ btnLabel }}
      </button>
    </div>
    <div style="margin-top:20px">
      <p>Ainda não possui conta? <router-link class="link" to="/auth/registro" style="text-decoration:none"><strong>Cadastre-se</strong></router-link></p>
    </div>
  <div class="version">v.{{ version }}</div>
  </div>
</template>

<script>
import Vue from 'vue'
import pgJson from '../../../../../package.json'
export default Vue.extend({
  props: {
    loading: Boolean
  },
  methods: {
    login () {
      this.$emit('login', true)
    }
  },
  computed: {
    version () {
      return pgJson.version
    },
    btnLabel () {
      return this.loading ? 'Entrando...' : 'Login'
    }
  }
})
</script>

<style scoped>
.actions-content {
  margin: 1rem 0.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.version {
  text-align: right;
  font-size: 12px;
  margin-top: 2rem;
  display: block;
}
.actions-content button {
  text-transform: capitalize;
  width: 9rem;
}
.link {
  color: #14a949;
}
</style>
